import * as validation from './validation';

//Verifica os campos do usuario
export function checkUserInput(input) {
    var message = [];
    if (!validation.validateName(input.name)) {
        message.push("Nome inválido (Deve conter apenas letras)");
    }
    if (!(validation.validateEmail(input.email)) && input.email.length > 0) {
        message.push("Email inválido");
    }

    //se for aluno da universidade, cpf obrigatorio
    if (!validation.validateCPF(input.cpf)) {
        message.push("CPF Inválido (CPF Obrigatório)");
    }
    return message;

}

//verifica os campos de um usuario ao registrar
export function checkUserRegisterInput(input) {
    var message = checkUserInput(input);
    if (input.password.length >= 8) {
        return message;
    } else {
        message.push("Senha inválida (Senha deve ter no mínimo 8 dígitos)");
    }
    return message;
}

//verifica os campos de login de um usuario
export function checkUserLogin(input) {
    var message = [];
    if (input.password.length < 8) {
        message.push("Senha inválida (Senha deve ter no mínimo 8 dígitos)");
    }
    if (!validation.validateEmail(input.email)) {
        message.push("Email Inválido");
    }
    return message;
}


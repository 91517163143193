//validar email
export function validateEmail(email) {
    var emailParts = email.split('@');
    if (emailParts.length !== 2) {
        return false;
    }
    var address = email.split('.');
    if (address.length === 1) {
        return false;
    }
    return true;
}

//Validar Nome
export function validateName(name) {
    const validNameFormat = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇ ]*$/i;
    return (validNameFormat.test(name));
}

//Validar CPF
export function validateCPF(CPF) {
    const validateCPF = require('validar-cpf');
    return validateCPF(CPF);
}

//Validar carga horaria
export function validateWorkload(workload) {
    if (!isNaN(workload)) {
        return true;
    }
    var splitWorkload = workload.split('.,');
    if (splitWorkload.length > 2) {
        return false;
    }

    var integerPart = parseInt(splitWorkload[0]);
    var decimalPart = parseInt(splitWorkload[1]);
    //a string que tentou converter nao é um num
    if (Object.is(integerPart, NaN) || (Object.is(decimalPart, NaN) && splitWorkload.length > 1)) {

        return false;
    }
    if (decimalPart > 0) {
        return false;
    }
    if (integerPart <= 0) {
        return false;
    }

    return true;
}

export function validateCourseName(courseName) {
    const validCourseNameFormat = /^[a-z0-9A-Za-zÀ-ÖØ-öø-ÿ\s()-]*$/i;
    return (validCourseNameFormat.test(courseName));
}
